import type { BaseResponse } from '@shared/models';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GetCalendarEventsReq, GetCalendarEventsRes, type GetUpcomingEventsReq, GetUpcomingEventsRes } from '@/views/calendar/types';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import { type GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import { GET_JOB_OFFERS } from '@/views/lobby/store/lobby.constants';
import { SearchJobOffersReq, SearchJobOffersRes } from '@/views/job/types';

@injectable()
export default class LobbyService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = `/lobby`;

  public [GET_UPCOMING_EVENTS](payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> {
    return this.apiService.post<GetUpcomingEventsRes>(`${this.baseUrl}/${GET_UPCOMING_EVENTS}`, payload, { allowOnceAtTime: true });
  }

  public [GET_CALENDAR_EVENTS](payload: GetCalendarEventsReq): Promise<GetCalendarEventsRes> {
    const { year = new Date().getFullYear(), month = new Date().getMonth() + 1 } = payload || {};

    return this.apiService.get<GetCalendarEventsRes>(`${this.baseUrl}/${GET_CALENDAR_EVENTS}?year=${year}&month=${month}`, { allowOnceAtTime: true });
  }

  public [GET_NOTIFICATIONS](payload: GetNotificationReq): Promise<GetNotificationsRes> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${GET_NOTIFICATIONS}`, payload);
  }

  public [GET_JOB_OFFERS](payload: SearchJobOffersReq): Promise<SearchJobOffersRes> {
    return this.apiService.post<SearchJobOffersRes>(`${this.baseUrl}/${GET_JOB_OFFERS}`, payload);
  }
}

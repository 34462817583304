import type { DirectiveBinding } from 'vue';

type Element = HTMLElement & { __keyDownEventListener__?: (event: KeyboardEvent) => void };

const DATA_ATTRIBUTE = 'key-down';

const getLastAttrIndex = (): number => {
  const elements = document.querySelectorAll(`[${DATA_ATTRIBUTE}]`);

  return Array.from(elements).reduce((max, element) => {
    const value = Number(element.getAttribute(DATA_ATTRIBUTE));

    return Math.max(max, isNaN(value) ? 0 : value);
  }, 0);
};

const eventListenerWrapper = (el: HTMLElement, callback: (ev: KeyboardEvent) => void): ((event: KeyboardEvent) => void) => {
  return (event: KeyboardEvent) => {
    if (el.getAttribute(DATA_ATTRIBUTE) !== getLastAttrIndex().toString()) {
      return;
    }

    callback(event);
  };
};

export const vueKeyDown = {
  mounted(el: Element, binding: DirectiveBinding<(ev: KeyboardEvent) => void>) {
    const index = (getLastAttrIndex() + 1).toString();
    el.setAttribute(DATA_ATTRIBUTE, index);

    const eventListener = eventListenerWrapper(el, binding.value);
    el.__keyDownEventListener__ = eventListener;
    window.addEventListener('keydown', eventListener);
  },
  unmounted(el: Element) {
    if (el.__keyDownEventListener__) {
      window.removeEventListener('keydown', el.__keyDownEventListener__);

      delete el.__keyDownEventListener__;
    }
  },
};

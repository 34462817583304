export const vueValidate = {
  mounted(el: HTMLElement) {
    const execute = (elem?: Element): boolean => {
      return Array.from(elem?.children || [])
        .map((child: Element) => {
          const isValid = !child.getAttribute('data-errors');

          return [child.children ? execute(child) : true, isValid].every((item) => item);
        })
        .every((isValid) => isValid);
    };

    const isValid = execute();

    if (!isValid) {
      el.setAttribute('validate', 'errors');
    } else {
      el.removeAttribute('validate');
    }
  },
};

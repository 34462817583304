<template>
  <div class="vz-calendar" :class="{ 'vz-calendar--loading': loading, 'vz-calendar--disabled': disabled }">
    <label v-if="label" class="text-body-2">{{ $t(label) }}</label>

    <div class="vz-calendar__header d-flex justify-space-between align-center ma-2">
      <vz-icon
        clickable
        name="svg:previous"
        size="1.25rem"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.PREVIOUS_MONTH' })"
        @click="onBack"
      />

      <div class="d-flex mx-2 gap-2 text-title-1">
        <span>{{ monthDisplay }}</span>
        <span>{{ yearDisplay }}</span>
      </div>

      <vz-icon
        clickable
        role="button"
        name="svg:arrow-right"
        size="1.25rem"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.NEXT_MONTH' })"
        @click="onNext"
      />
    </div>

    <div class="vz-calendar__weekdays text-body-2">
      <div v-for="(calendarDay, index) in calendar.weekdays" :key="calendarDay">{{ dayjs().day(index).format('ddd') }}</div>
    </div>

    <div class="vz-calendar__container">
      <vz-calendar-day
        v-for="(date, index) in matrixDays"
        :key="index"
        :class="{
          'vz-calendar__day': !!date,
          'vz-calendar__day--outline': dayBorder,
          'vz-calendar__day--today': date?.date === new Date(),
          'vz-calendar__day--active': date && today === date?.date.valueOf(),
          'vz-calendar__day--mark': date?.date && !!eventsMap[date?.date.valueOf()]?.length,
          'vz-calendar__day--disabled':
            disabled || (date?.date && minDate && date.date.valueOf() < minDate) || (date?.date && maxDate && date.date.valueOf() > maxDate),
        }"
        role="button"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.SELECT_DAY' })"
        :extendable="extendable"
        :day="date?.day || 0"
        @click="onSelect(date)"
      >
        <template v-if="date">
          <template v-if="dotMark && eventsMap[date?.date.valueOf() || 0]?.length">
            <div
              v-for="(event, eventIndex) in eventsMap[date?.date.valueOf() || 0]"
              :key="eventIndex"
              class="vz-calendar__day-mark"
              :style="{ color: event.color, width: event.percentOfDay + '%' }"
            >
              {{ event.title }}
            </div>
          </template>

          <template v-else>
            <template v-for="(event, eventIndex) in eventsMap[date?.date.valueOf() || 0]" :key="eventIndex">
              <vz-badge class="max-line-2" :prefix="event.time" :text="event.title" :color="event.color" />
            </template>
          </template>
        </template>
      </vz-calendar-day>
    </div>

    <div :class="['vz-calendar__error', { 'vz-calendar__error--hidden': hideDetails }]">
      <p v-if="validateMessage" :class="{ 'vz-datepicker__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>

      <p v-else-if="errorMessage">{{ $t(errorMessage) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { CalendarDay } from '@/shared/services/calendar-service/types/calendar-day';
import { computed, type PropType, ref, watch } from 'vue';
import Calendar from '@/shared/services/calendar-service/calendar.service';
import VzCalendarDay from '@/shared/components/calendar/components/vz-calendar-day.vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import dayjs from 'dayjs';
import { useValidator } from '@shared/components/fields/helpers';

const t = useTranslator();

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  viewDate: { type: Object as PropType<Date | undefined | null>, default: undefined },
  modelValue: { type: Object as PropType<Date | undefined | null>, required: true },
  label: { type: String, default: '' },
  minDate: { type: Number, default: undefined }, // timestamp
  maxDate: { type: Number, default: undefined }, // timestamp
  events: { type: Array as PropType<Array<Record<string, any>>>, default: () => [] }, // events
  idKey: { type: String, default: '_id' }, // event id key name
  titleKey: { type: String, default: 'title' }, // event title key name
  dateFromKey: { type: String, default: 'dateFrom' }, // event start date key name
  dateToKey: { type: String, default: 'dateTo' }, // event end date key name
  colors: { type: Object as PropType<{ key: string; map: Record<string, string> } | undefined>, default: undefined },
  loading: { type: Boolean, default: false },
  extendable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  errorMessage: { type: String as PropType<string | null>, default: null },
  dayBorder: { type: Boolean, default: true },
  dotMark: { type: Boolean, default: false },
});

const emit = defineEmits(['update:events', 'update:view', 'update:model-value']);
const localDate = ref<number>((props.modelValue ? new Date(props.modelValue) : new Date()).valueOf());

const calendar = new Calendar({
  idKey: props.idKey,
  titleKey: props.titleKey,
  fromKey: props.dateFromKey,
  toKey: props.dateToKey,
  colors: props.colors,
});

const vModel = computed({
  get: (): Date => {
    if (props.modelValue) {
      return new Date(props.modelValue);
    } else if (props.minDate) {
      return new Date(props.minDate);
    }

    return new Date();
  },
  set: (value) => {
    localDate.value = value.valueOf();

    emit('update:model-value', value.valueOf());
  },
});

const today = computed(() => new Date(vModel.value.valueOf()).setHours(0, 0, 0, 0).valueOf());

const monthDisplay = computed(() => dayjs().month(new Date(localDate.value).getMonth()).format('MMMM'));
const yearDisplay = computed(() => dayjs().year(new Date(localDate.value).getFullYear()).format('YYYY'));
const currentValue = computed(() => localDate.value.valueOf());
const currentYear = computed(() => new Date(localDate.value).getFullYear());
const currentMonth = computed(() => new Date(localDate.value).getMonth());
const matrixDays = computed((): Array<CalendarDay | null> => calendar.days(currentYear.value, currentMonth.value));
const eventsMap = computed(() => calendar.events(props.events));
const dayEvents = computed(() => eventsMap.value[currentValue.value]);

const onSelect = (date: CalendarDay | null) => {
  if (!date) {
    return;
  }

  vModel.value = date.date;
};

const onNext = () => {
  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 11) {
    date.setFullYear(year + 1);
    date.setMonth(0);
  } else {
    date.setMonth(month + 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

const onBack = () => {
  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 0) {
    date.setFullYear(year - 1);
    date.setMonth(11);
  } else {
    date.setMonth(month - 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

watch(
  () => [dayEvents.value],
  () => emit('update:events', dayEvents.value),
  { immediate: true }
);

const { validateMessage, isTouched } = useValidator(
  computed(() => vModel.value.valueOf()),
  computed(() => props.rules),
  props.name || props.label
);
</script>

<style lang="scss">
.vz-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    user-select: none;

    svg {
      @include rtl(transform, scale(-1));
    }

    @include min-tablet-layout {
      svg {
        height: 24px;
        width: 24px;
      }

      > div > span {
        font-size: 24px;
      }
    }

    @include max-tablet-layout {
      svg {
        height: 14px;
        width: 14px;
      }

      > div > span {
        font-size: 18px;
      }
    }
  }

  &__weekdays {
    position: relative;
    display: flex;
    font-weight: var(--font-weight-medium);
    margin: 0.5rem 0;

    .vz-calendar--loading & {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25rem;
        background-image: linear-gradient(100deg, transparent 5%, var(--color-primary-900) 42.5%, transparent 95%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-template-rows: repeat(6, calc(100% / 6));
    flex-grow: 1;

    > * {
      margin: 1px;
    }
  }

  &__day {
    position: relative;
    transition: background-color 0.3s;
    overflow: hidden;

    &--mark:has(.vz-calendar__day-mark) {
      color: var(--color-primary-400) !important;
    }

    &-mark {
      max-height: 0.25rem;
      border-radius: 0.5rem;
      max-width: 1.5rem;
      background-color: currentColor;
    }

    @include min-mobile-layout {
      border-radius: var(--border-radius-regular);
    }

    @include max-tablet-layout {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      > *:not(:first-child) {
        display: none;
      }

      &--mark {
        > div {
          position: relative;
          width: 100%;

          &:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -0.25rem;
            width: 60%;
            height: 25%;
            max-width: 0.5rem;
            max-height: 0.5rem;
            background-color: var(--color-primary-900) !important;
            border-radius: var(--border-radius-regular);
            transform: translateX(-50%);
          }
        }
      }
    }

    @include min-tablet-layout {
      > div:first-child {
        font-weight: var(--font-weight-medium);
      }
    }

    &-events {
      @include max-tablet-layout {
        display: none;
      }
    }

    &--today {
      color: var(--color-primary-900);

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: currentColor;
        opacity: 0.1;
      }
    }

    &--active {
      background-color: var(--color-background-regular);
      outline: var(--outline-focus);
    }

    &--disabled {
      color: var(--color-disabled);
    }

    > * {
      margin: 2px;
    }
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "message-card__header-sender" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 4,
  class: "message-card__content-state"
}

import { computed, type PropType, ref } from 'vue';
import type { BaseMessage } from '@/views/messenger/types';
import type { BaseChat } from '@/views/messenger/types';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { timeFromNow } from '@/views/calendar/helpers';
import { linkify } from '@shared/helpers/linkify';
import emojiRegex from 'emoji-regex';
import { useSwipe } from '@shared/composables/use-swipe';
import { isRtl } from '@/plugins/i18n/helpers';
import VzAttachment from '@shared/components/vz-attachment.vue';
import { GET_ATTACHMENT } from '@/store/client/client.constants';
import useClientStore from '@/store/client/composables/use-client-store';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-card',
  props: {
  disabled: { type: Boolean, default: false },
  message: { type: Object as PropType<BaseMessage>, required: true },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
  online: { type: Array as PropType<Array<string>>, default: () => [] },
  status: { type: String as PropType<string | undefined>, default: undefined },
  avatarSize: { type: [Number, String], default: 32 },
},
  emits: ['visible', 'hidden', 'audio:playback-end', 'reply:message', 'select:participant'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const { [GET_ATTACHMENT]: getAttachment } = useClientStore().useActions([GET_ATTACHMENT]);

const { isMe, myId } = useAuthUser();

const audioPlayerRef = ref();
const elementRef = ref<HTMLElement | undefined>(undefined);
const includeAttach = computed(() => !!props.message?.attachments?.length || !!props.message?.audioId || !!props.message?.coordinates);

useSwipe(elementRef, {
  isRtl: ref(isRtl()),
  minStepSize: 80,
  readonly: computed(() => props.disabled),
  onNext: () => emit('reply:message', props.message),
  onBack: () => emit('reply:message', props.message),
});

const hasRead = computed(() => !!(props.message?.readBy || {})[myId.value]);

const onVisible = () => {
  if (hasRead.value) {
    return;
  }

  emit('visible', props.message);
};

const onHidden = () => {
  if (hasRead.value) {
    return;
  }

  emit('hidden', props.message);
};

const isRead = computed((): 'SOME' | 'EVERY' | false => {
  const readBy = Object.keys(props.message?.readBy || {});
  const participantsIds = props.participants?.filter(({ _id }) => !isMe(_id)).map(({ _id }) => _id);

  if (!readBy.length) {
    return false;
  }

  if (participantsIds?.every((id) => readBy.includes(id))) {
    return 'EVERY';
  } else if (participantsIds?.some((id) => readBy.includes(id))) {
    return 'SOME';
  }

  return false;
});

const isSent = computed((): 'SOME' | 'EVERY' | false => {
  const sentTo = [...new Set([...Object.keys(props.message?.sentTo || {}), ...Object.keys(props.message?.readBy || {})])];
  const participantsIds = props.participants?.filter(({ _id }) => !isMe(_id)).map(({ _id }) => _id);

  if (!sentTo.length) {
    return false;
  }

  if (participantsIds.every((id) => sentTo.includes(id))) {
    return 'EVERY';
  } else if (participantsIds.some((id) => sentTo.includes(id))) {
    return 'SOME';
  }

  return false;
});

const htmlMessage = computed(() => {
  if (!props.message?.message) {
    return null;
  }

  return linkify(props.message.message).replace(/(\r\n|\r|\n)/g, '<br>');
});

const style = computed((): Record<string, string> => {
  const match = props.message?.message?.match(emojiRegex());

  return match?.length === 1 && match[0] === props.message?.message ? { fontSize: '2.5rem', textAlign: 'center' } : {};
});

const user = computed(() => (props.participants || []).find(({ _id }) => _id === props.message?.fromId));
const isOnline = computed(() => (user.value ? props.online?.includes(user.value._id) : false));

__expose({ play: () => audioPlayerRef.value?.play() });

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!
  const _component_message_card = _resolveComponent("message-card", true)!
  const _component_vz_audio = _resolveComponent("vz-audio")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _directive_visibility = _resolveDirective("visibility")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref_key: "elementRef",
    ref: elementRef,
    class: _normalizeClass(['message-card', { 'message-card--me': _unref(isMe)(__props.message.fromId) && !__props.disabled, [`message-card--${__props.message.key}`]: __props.message.key }])
  }, [
    _createElementVNode("div", _mergeProps({ class: "message-card__header" }, _toHandlers(!_unref(isMe)(__props.message.fromId) ? { click: () => _ctx.$emit('select:participant', __props.message.fromId) } : {}, true)), [
      _createVNode(_component_vz_avatar, {
        hid: user.value?._id,
        "first-name": user.value?.firstName,
        "last-name": user.value?.lastName,
        color: isOnline.value ? 'green-500' : undefined,
        size: __props.avatarSize
      }, null, 8, ["hid", "first-name", "last-name", "color", "size"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(user.value?.firstName), 1),
          _createElementVNode("span", null, _toDisplayString(user.value?.lastName), 1),
          _createElementVNode("span", null, _toDisplayString(user.value?.nickName), 1)
        ]),
        (__props.message.updatedAt)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(timeFromNow)(__props.message.updatedAt)), 1))
          : _createCommentVNode("", true),
        (__props.status)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(__props.status)), 1))
          : _createCommentVNode("", true)
      ])
    ], 16),
    _createElementVNode("div", {
      class: _normalizeClass(['message-card__content', { 'min-width-120': includeAttach.value }])
    }, [
      _createElementVNode("div", null, [
        (__props.message.replyTo)
          ? (_openBlock(), _createBlock(_component_message_card, {
              key: 0,
              class: "border-regular border-radius-medium pa-2 bg-mono-200 mb-2",
              disabled: "",
              "avatar-size": "24",
              online: __props.online,
              participants: __props.participants,
              message: __props.message.replyTo
            }, null, 8, ["online", "participants", "message"]))
          : _createCommentVNode("", true)
      ]),
      (__props.message.attachments?.length)
        ? (_openBlock(), _createBlock(VzAttachment, {
            key: 0,
            readonly: "",
            "accept-type": "image/jpeg,image/png,application/pdf",
            value: __props.message.attachments,
            provider: _unref(getAttachment)
          }, null, 8, ["value", "provider"]))
        : _createCommentVNode("", true),
      (__props.message.coordinates)
        ? (_openBlock(), _createBlock(MessageShareLocation, {
            key: 1,
            coordinates: __props.message.coordinates
          }, null, 8, ["coordinates"]))
        : _createCommentVNode("", true),
      (htmlMessage.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            style: _normalizeStyle(style.value),
            innerHTML: htmlMessage.value
          }, null, 12, _hoisted_4))
        : _createCommentVNode("", true),
      (__props.message.audioId)
        ? (_openBlock(), _createBlock(_component_vz_audio, {
            key: 3,
            ref_key: "audioPlayerRef",
            ref: audioPlayerRef,
            hid: __props.message.audioId,
            onEnd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('audio:playback-end')))
          }, null, 8, ["hid"]))
        : _createCommentVNode("", true),
      (_unref(isMe)(user.value?._id))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (isSent.value)
              ? (_openBlock(), _createBlock(_component_vz_icon, {
                  key: 0,
                  name: "svg:check",
                  size: "12px",
                  color: isSent.value === 'SOME' ? 'primary-300' : 'green-400'
                }, null, 8, ["color"]))
              : _createCommentVNode("", true),
            (isRead.value)
              ? (_openBlock(), _createBlock(_component_vz_icon, {
                  key: 1,
                  name: "svg:check",
                  size: "12px",
                  color: isRead.value === 'SOME' ? 'primary-300' : 'green-400'
                }, null, 8, ["color"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2)), [
    [_directive_visibility, { onVisible: onVisible, onHidden: onHidden }]
  ])
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-title-3 text-center text-ellipsis"
}
const _hoisted_2 = {
  key: 1,
  flat: "",
  class: "tab-switcher-navigation"
}
const _hoisted_3 = { class: "tab-switcher-navigation__panel" }
const _hoisted_4 = { class: "d-flex overflow-hidden" }
const _hoisted_5 = {
  key: 0,
  class: "tab-switcher-navigation__panel-actions"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "fill-width text-ellipsis text-center" }
const _hoisted_8 = {
  key: 0,
  class: "tab-switcher-navigation__panel-actions"
}

import type { SwitchTab } from '@/shared/components/content-switcher/tab-switcher.type';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { SizeUnit } from '@shared/types';
import { routeTo } from '@shared/composables';
import { useFormValidator } from '@shared/components/fields/helpers';
import { isEqual } from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-tab-switcher',
  props: {
  hideActiveStyle: { type: Boolean, default: false },
  autoActiveLast: { type: Boolean, default: false },
  hideSingleTab: { type: Boolean, default: false },
  fit: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  stepper: { type: Boolean, default: false },
  sticky: { type: Boolean, default: false },
  minTabWidth: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hideTabs: { type: Boolean, default: false },
  uppercase: { type: Boolean, default: true },
  autofocus: { type: Boolean, default: true },
  index: { type: [Number, String] as PropType<string | number | undefined>, default: undefined },
  tabs: { type: [Number, Array] as PropType<Array<string | number | SwitchTab> | number>, required: true },
  numOfShown: { type: [Number, String], default: 4 },
  hideNextButton: { type: Boolean, default: false },
  hideBackButton: { type: Boolean, default: false },
  hideTabsOnSplash: { type: Boolean, default: false },
  enableEmptyState: { type: Boolean, default: true },
  hiddenIndexList: { type: Array as PropType<Array<number>>, default: () => [] },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
  beforeValidationCallback: { type: Function as PropType<(() => void | boolean) | undefined>, default: undefined },
},
  emits: ['update:index', 'update:name', 'more'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const slots = useSlots();

const startAt = ref<number>(0);
const tabIndex = ref<number>(props.index !== undefined ? +props.index : 0);
const contentRef = ref<HTMLDivElement | undefined>(undefined);

const switcherTabs = computed((): Array<SwitchTab> => {
  if (Array.isArray(props.tabs)) {
    return props.tabs.map((value) => (typeof value === 'object' ? value : { label: value.toString() })).filter(({ hidden }) => !hidden);
  }

  return Array.from(Array(+props.tabs).keys()).map((num) => ({ label: (num + 1).toString() }));
});

const vIndex = computed({
  get: () => tabIndex.value,
  set: (value: number) => {
    tabIndex.value = value;
    emit('update:index', value);
    emit('update:name', tabName.value);
    setCenterView();
  },
});

const validate = (isSilent?: boolean): boolean => {
  props.beforeValidationCallback?.();

  if (switcherTabs.value?.[vIndex.value]?.validate) {
    const isValid = useFormValidator(contentRef, isSilent);

    return isValid();
  }

  return true;
};

const isContentExists = computed(
  (): boolean => !!slots['content'] || !!slots['default'] || !!slots[`${tabName.value || vIndex.value + 1}`] || !!slots['splash']
);

const contentClass = computed(() => {
  return [
    'tab-switcher-content',
    `tab-switcher-content-${vIndex.value}`,
    { 'tab-switcher-content--splash': slots['splash'] },
    ...(Array.isArray(props.class) ? props.class : [props.class]),
  ];
});

const shownLimit = computed((): number => +props.numOfShown);

const isTabsShown = computed((): boolean => {
  if (props.hideSingleTab && switcherTabs.value.length <= 1) {
    return false;
  }

  return !!switcherTabs.value.length && !props.hiddenIndexList.includes(vIndex.value);
});

const isTabsHideOnSplash = computed((): boolean => !!slots['splash'] && props.hideTabsOnSplash);

const tabName = computed(() => switcherTabs.value?.[vIndex.value]?.name);
const tabStyle = computed(() => switcherTabs.value?.[vIndex.value]?.style);

const totalTabs = computed((): number => switcherTabs.value.length);
const lastTab = computed((): SwitchTab => switcherTabs.value[totalTabs.value - 1]);
const chunkSize = computed((): number => Math.max(shownLimit.value - (isLastChunk.value ? 0 : 2), 1));
const isLastChunk = computed(
  (): boolean => props.index === totalTabs.value - 1 || totalTabs.value - shownLimit.value === startAt.value || !isOverSize.value
);
const tabsChunk = computed((): Array<SwitchTab> => switcherTabs.value.slice(startAt.value, startAt.value + chunkSize.value));
const activeShownIndex = computed(() => vIndex.value - startAt.value);
const isOverSize = computed((): boolean => totalTabs.value > shownLimit.value);

const isActiveIsFocused = computed((): boolean => {
  return vIndex.value < startAt.value || vIndex.value < startAt.value + chunkSize.value;
});

const setCenterView = (): void => {
  if (isActiveIsFocused.value) {
    return;
  }

  startAt.value = Math.min(totalTabs.value - shownLimit.value, vIndex.value + Math.floor(shownLimit.value / 2) + chunkSize.value);
};

const isPrevPageEnabled = computed((): boolean => vIndex.value > 0 || (!props.autofocus && startAt.value > 0));

const onPrevPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.max(0, vIndex.value - 1));
  }

  if (!props.autofocus || vIndex.value <= startAt.value) {
    startAt.value = Math.max(0, startAt.value + (vIndex.value - startAt.value - 1));
  }
};

const isNextPageEnabled = computed((): boolean => (totalTabs.value - 1 > vIndex.value && props.autofocus) || !isLastChunk.value);

const onNextPage = (): void => {
  if (props.autofocus) {
    onSelect(Math.min(totalTabs.value - 1, vIndex.value + 1));
  }

  if (!props.autofocus || vIndex.value >= startAt.value + chunkSize.value - 1) {
    startAt.value = Math.min(totalTabs.value - shownLimit.value, startAt.value + chunkSize.value);
  }
};

const onSelect = (index: number, isClicked: boolean = false): void => {
  if (!validate()) {
    return;
  }

  if (isClicked) {
    switcherTabs.value[index]?.onClick?.(index);
  }

  if (switcherTabs.value[index]?.route) {
    routeTo(switcherTabs.value[index].route!);
  }

  vIndex.value = index;
};

watch(
  () => switcherTabs.value,
  (newValue, oldValue) => {
    if (!validate() || newValue.length === oldValue?.length) {
      return;
    }

    const findNewIndex = newValue.findIndex((tab) => !oldValue.find((oldTab) => isEqual(oldTab, tab)));

    if (findNewIndex !== -1) {
      onSelect(findNewIndex);
      contentRef.value?.removeAttribute('validate');
    }
  },
  { deep: true }
);

watch(
  () => props.index,
  (index) => {
    if (!validate() || index === undefined || +index === tabIndex.value) {
      return;
    }

    vIndex.value = +index;
  }
);

watch(
  () => [switcherTabs.value, props.autoActiveLast],
  () => {
    if (props.autoActiveLast) {
      nextTick(() => {
        vIndex.value = totalTabs.value - 1;
      });
    }
  },
  { immediate: true }
);

__expose({ validate, next: onNextPage, previous: onPrevPage, select: onSelect, index: vIndex, name: tabName });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'tab-switcher',
      { 'tab-switcher--with-content': isContentExists.value, 'tab-switcher--flat': __props.flat, 'tab-switcher--fit': __props.fit, 'tab-switcher--stepper': __props.stepper },
    ])
  }, [
    (!__props.hideTabs)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['tab-switcher-header', { 'tab-switcher-header--active': _ctx.$slots['header'] || __props.title, 'sticky-top': __props.sticky }])
        }, [
          (__props.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t(__props.title)), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "header", {
            index: vIndex.value,
            tab: tabName.value
          }),
          (isTabsShown.value && !isTabsHideOnSplash.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (isOverSize.value && !__props.hideBackButton)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(['tab-switcher-navigation__back', { 'tab-switcher-navigation__back--disabled': !isPrevPageEnabled.value }]),
                      onClick: onPrevPage
                    }, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:previous",
                        size: "1rem",
                        "aria-label": _ctx.$t('GENERAL.BACK'),
                        color: isPrevPageEnabled.value ? 'currentColor' : 'mono-300'
                      }, null, 8, ["aria-label", "color"])
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.$slots['prepend'])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _renderSlot(_ctx.$slots, "prepend", {
                            index: vIndex.value,
                            tab: tabName.value
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsChunk.value, ({ label, iconName, color }, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass([
                'tab-switcher-navigation__panel-tab',
                {
                  'tab-switcher-navigation__panel-tab--active': !__props.hideActiveStyle && activeShownIndex.value === index,
                  'tab-switcher-navigation__panel-tab--mark': activeShownIndex.value === index,
                  'text-uppercase': __props.uppercase,
                  'justify-center': +__props.numOfShown > 1,
                },
              ]),
                        style: _normalizeStyle({ minWidth: __props.minTabWidth, color: `var(--color-${color})` }),
                        onClick: ($event: any) => (onSelect(startAt.value + index, true))
                      }, [
                        iconName
                          ? (_openBlock(), _createBlock(_component_vz_icon, {
                              key: 0,
                              name: iconName,
                              size: "1.25rem"
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(label ? _ctx.$t(label.toString()) : null), 1)
                      ], 14, _hoisted_6))
                    }), 128)),
                    (!isLastChunk.value && chunkSize.value > 1)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("div", {
                            class: "tab-switcher-navigation__panel-tab max-fit-width",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('more', { index: __props.index, tabs: switcherTabs.value })))
                          }, _cache[2] || (_cache[2] = [
                            _createElementVNode("span", null, "...", -1)
                          ])),
                          _createElementVNode("div", {
                            class: "tab-switcher-navigation__panel-tab text-ellipsis overflow-hidden",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (onSelect(totalTabs.value - 1)))
                          }, _toDisplayString(_ctx.$t(lastTab.value.label)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.$slots['append'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _renderSlot(_ctx.$slots, "append", { index: vIndex.value })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (isOverSize.value && !__props.hideNextButton)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(['tab-switcher-navigation__next', { 'tab-switcher-navigation__next--disabled': !isNextPageEnabled.value }]),
                      onClick: onNextPage
                    }, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:arrow-right",
                        size: "1rem",
                        "aria-label": _ctx.$t('GENERAL.NEXT'),
                        color: isNextPageEnabled.value ? 'currentColor' : 'mono-300'
                      }, null, 8, ["aria-label", "color"])
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (isContentExists.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref_key: "contentRef",
          ref: contentRef,
          class: _normalizeClass(contentClass.value),
          style: _normalizeStyle(tabStyle.value)
        }, [
          (_ctx.$slots['splash'])
            ? _renderSlot(_ctx.$slots, "splash", { key: 0 })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.$slots[tabName.value || vIndex.value + 1])
                  ? _renderSlot(_ctx.$slots, tabName.value || vIndex.value + 1, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)))
                  : _renderSlot(_ctx.$slots, "default", _mergeProps({
                      key: 1,
                      index: vIndex.value
                    }, _ctx.$attrs))
              ], 64))
        ], 6))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
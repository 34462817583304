import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import LobbyService from '@/views/lobby/service/lobby.service';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import { GET_JOB_OFFERS } from '@/views/lobby/store/lobby.constants';
import type { GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsReq, GetUpcomingEventsRes } from '@/views/calendar/types';
import type { SearchJobOffersReq, SearchJobOffersRes } from '@/views/job/types';

const lobbyService = container.resolve(LobbyService);

export const useLobbyStore = defineStore(StoreNamespace.LOBBY_MODULE, () => {
  return {
    [GET_UPCOMING_EVENTS]: async (payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> => await lobbyService[GET_UPCOMING_EVENTS](payload),
    [GET_CALENDAR_EVENTS]: async (payload: GetCalendarEventsReq): Promise<GetCalendarEventsRes> => await lobbyService[GET_CALENDAR_EVENTS](payload),
    [GET_NOTIFICATIONS]: async (payload: GetNotificationReq): Promise<GetNotificationsRes> => await lobbyService[GET_NOTIFICATIONS](payload),
    [GET_JOB_OFFERS]: async (payload: SearchJobOffersReq): Promise<SearchJobOffersRes> => await lobbyService[GET_JOB_OFFERS](payload),
  };
});

import Modules from '@/router/constants/modules';

export class StoreNamespace {
  public static readonly GENERAL_MODULE: string = 'general';
  public static readonly LOBBY_MODULE: string = 'lobby';
  public static readonly AUTH_MODULE: string = 'auth';
  public static readonly NOTIFICATION_MODULE: string = 'notifications';
  public static readonly EMPLOYEES_MODULE: string = 'employees';
  public static readonly ALBUM_MODULE: string = 'album';
  public static readonly JOBS_MODULE: string = 'jobs';
  public static readonly JOB_MANAGER_MODULE: string = Modules.MANAGER.JOB_MANAGER;
  public static readonly JOB_MANAGER_POST_MODULE: string = Modules.MANAGER.JOB_MANAGER_POST;
  public static readonly JOB_MANAGER_PROJECT_MODULE: string = Modules.MANAGER.JOB_MANAGER_PROJECT;
  public static readonly JOB_MANAGER_ASSIGNEE_MODULE: string = Modules.MANAGER.JOB_MANAGER_ASSIGNEE;
  public static readonly JOB_MANAGER_TEMPLATE_MODULE: string = Modules.MANAGER.JOB_MANAGER_TEMPLATE;
  public static readonly CALENDAR_MODULE: string = 'calendar';
  public static readonly MESSENGER_MODULE: string = 'messenger';
  public static readonly GROUPS_MODULE: string = 'groups';
  public static readonly GEAR_MODULE: string = 'gear';
  public static readonly SETTINGS_MODULE: string = 'settings';
  public static readonly BACKOFFICE_LOG_MODULE: string = Modules.BACKOFFICE_LOG;
  public static readonly BACKOFFICE_USERS_MODULE: string = Modules.BACKOFFICE_USERS;
}

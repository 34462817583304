import { type DirectiveBinding } from 'vue';

type Element = HTMLElement & {
  __visibility__?: { observer: IntersectionObserver; timeoutId: ReturnType<typeof setTimeout> | undefined };
};

type Callback = (el?: HTMLElement, observer?: IntersectionObserver) => void;

type VisibilityValue = {
  onVisible?: Callback | [Callback, number?];
  onHidden?: Callback | [Callback, number?];
};

export const vueVisibility = {
  mounted(el: Element, binding: DirectiveBinding<VisibilityValue | [VisibilityValue, number?]>) {
    const [callbacks, timeout = 0] = Array.isArray(binding.value) ? binding.value : [binding.value, 0];
    const { onVisible, onHidden } = callbacks || {};
    const [visibleCallback, visibleTimeout = timeout] = Array.isArray(onVisible) ? onVisible : [onVisible, timeout];
    const [hiddenCallback, hiddenTimeout = timeout] = Array.isArray(onHidden) ? onHidden : [onHidden, timeout];

    let visibilityTimeoutId: ReturnType<typeof setTimeout> | undefined = el.__visibility__?.timeoutId;

    const visibleHandler = (observer: IntersectionObserver): void => {
      clearTimeout(visibilityTimeoutId);

      visibilityTimeoutId = setTimeout(() => {
        visibleCallback?.(el, observer);
      }, visibleTimeout);
    };

    const hiddenHandler = (observer: IntersectionObserver): void => {
      clearTimeout(visibilityTimeoutId);

      setTimeout(() => {
        hiddenCallback?.(el, observer);
      }, hiddenTimeout);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          (entry.intersectionRatio > 0 ? visibleHandler : hiddenHandler)?.(observer);
        });
      },
      { root: document.documentElement }
    );

    observer.observe(el);
    el.__visibility__ = { observer, timeoutId: visibilityTimeoutId };
  },
  unmounted(el: Element) {
    el.__visibility__?.observer.disconnect();
    clearTimeout(el.__visibility__?.timeoutId);
  },
};

import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-2 my-1" }
const _hoisted_2 = ["value"]

import { computed, type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-toggle-switch',
  props: {
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const label = computed(() => {
  if (props.label) {
    return props.label;
  }

  return props.modelValue ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE';
});

const vModel = computed({
  get: (): boolean => !!props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onClick = (): void => {
  if (props.disabled || props.loading) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(['vz-toggle-switch text-ellipsis', { 'vz-toggle-switch--checked': vModel.value, 'vz-toggle-switch--disabled': __props.disabled || __props.loading }])
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        value: vModel.value,
        onChange: _withModifiers(onClick, ["stop"])
      }, null, 40, _hoisted_2),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "vz-toggle-switch__switch" }, null, -1)),
      (label.value || _ctx.$slots['label'])
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['ms-1', { 'c-primary-900': vModel.value, 'c-mono-600': !vModel.value, 'c-mono-400': __props.disabled || __props.loading }])
          }, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(label.value)), 1)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})
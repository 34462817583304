import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, toHandlers as _toHandlers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "vz-avatar__loading"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 3,
  class: "vz-avatar__image-wrapper"
}
const _hoisted_4 = ["src", "alt", "title"]

import type { SizeUnit } from '@shared/types';
import type { ColorName } from '@shared/services/css-service/types';
import { computed, type PropType, ref, watch } from 'vue';
import ImageCropper from '@/shared/components/vz-image-cropper.vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import useEmployeeStore from '@/views/employee/composables/use-employee-store';
import { GET_AVATAR } from '@/views/employee/store/employee.constants';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-avatar',
  props: {
  fixSize: { type: Boolean, default: false },
  externalEditor: { type: Boolean, default: false },
  modelValue: { type: String as PropType<string | undefined | null>, default: undefined },
  size: { type: [String, Number], default: 32 },
  verified: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  editable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  hid: { type: String as PropType<string | undefined>, default: undefined },
  color: { type: String as PropType<ColorName | undefined>, default: undefined },
  cropSize: { type: Number, default: 192 },
  firstName: { type: String as PropType<string | undefined>, required: true },
  lastName: { type: String as PropType<string | undefined>, required: true },
},
  emits: ['update:model-value', 'edit'],
  setup(__props, { emit: __emit }) {

const stringToDarkColor = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + (value & 0x7f).toString(16)).slice(-2);
  }

  return color;
};

const t = useTranslator();

const props = __props;

const emit = __emit;

const vModel = computed({
  get: (): string | null | undefined => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const displayText = computed(() => {
  const first = props.firstName || '';
  const second = props.lastName || '';

  return `${first?.[0] || ''}${second?.[0] || ''}`.toUpperCase();
});

const displayColor = computed(() => stringToDarkColor(displayText.value));

const { useActions } = useEmployeeStore();
const { [GET_AVATAR]: getAvatarAction } = useActions([GET_AVATAR]);

const errorLoading = ref<boolean>(false);
const localLoading = ref<boolean>(false);
const isEditMode = ref<boolean>(false);

const hidSrc = ref<string | undefined>('');
const hidAlt = ref<string | undefined>(undefined);
const isDefaultSrc = computed(() => !hidSrc.value && !vModel.value && displayText.value);
const imgSrc = computed((): string => (!errorLoading.value && (hidSrc.value || vModel.value)) || defaultSrc.value);
const defaultSrc = computed(() => require('@/assets/images/avatar.svg'));

const adjSize = computed(() => (isEditMode.value ? Math.min(props.cropSize, +props.size * 2) : +props.size));
const iconSize = computed((): SizeUnit => `${adjSize.value / 2.5}px`);
const sizeStyle = computed(() => (props.fixSize ? `${adjSize.value}px` : `calc(${adjSize.value}px * var(--screen-scale))`));

const avatarStyle = computed(() => ({
  width: `calc(${sizeStyle.value} - 1px)`,
  height: `calc(${sizeStyle.value} - 1px)`,
  borderWidth: Math.ceil((adjSize.value / 32) * 1.1) + 'px',
  ...(props.color ? { borderColor: getThemeColor(props.color) } : {}),
}));
const spinnerStyle = computed(() => ({
  height: sizeStyle.value,
  width: sizeStyle.value,
  borderWidth: Math.ceil(adjSize.value / 24) + 'px',
}));
const isLoading = computed(() => props.loading || localLoading.value);

const onSave = ({ data }: { data: string | null }) => {
  emit('update:model-value', data || null);
  hidSrc.value = data || '';
  isEditMode.value = false;
};

watch(
  () => props.hid,
  async () => {
    if (!props.hid) {
      return;
    }

    try {
      localLoading.value = true;
      const { image, alt } = await getAvatarAction([props.hid]);

      hidSrc.value = image;
      hidAlt.value = alt;
    } catch (e) {
      hidSrc.value = defaultSrc.value;
    } finally {
      localLoading.value = false;
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-avatar', { 'vz-avatar--edit': isEditMode.value }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'vz-avatar__image-container',
        { 'vz-avatar__image-container--editable': __props.editable, 'vz-avatar__image-container--default': isDefaultSrc.value },
      ]),
      style: _normalizeStyle({ width: sizeStyle.value, height: sizeStyle.value, '--default-avatar': displayColor.value })
    }, [
      (isDefaultSrc.value && !isEditMode.value)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "d-flex align-center justify-center fill-height absolute z-index-1 fill-width font-weight-500 c-mono-100 z-index-10",
            style: _normalizeStyle({ fontSize: `${Math.floor(+__props.size / 2)}px` })
          }, _toDisplayString(displayText.value), 5))
        : _createCommentVNode("", true),
      (isEditMode.value)
        ? (_openBlock(), _createBlock(ImageCropper, _mergeProps({
            key: 1,
            "auto-zoom": "",
            class: "fill-width",
            "export-type": "base64",
            ratio: 1,
            size: __props.cropSize,
            quality: 0.7,
            src: vModel.value || hidSrc.value,
            "default-src": defaultSrc.value,
            "preview-height": adjSize.value,
            "preview-width": adjSize.value,
            "stencil-height": adjSize.value,
            "stencil-width": adjSize.value
          }, _ctx.$attrs, {
            onSave: onSave,
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (isEditMode.value = false))
          }), null, 16, ["size", "src", "default-src", "preview-height", "preview-width", "stencil-height", "stencil-width"]))
        : (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("img", {
                class: "vz-avatar__image",
                role: "presentation",
                src: imgSrc.value,
                style: _normalizeStyle(avatarStyle.value),
                alt: ""
              }, null, 12, _hoisted_2),
              _createElementVNode("span", {
                style: _normalizeStyle(spinnerStyle.value)
              }, null, 4)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", _mergeProps({
                class: "vz-avatar__image",
                role: "presentation",
                src: imgSrc.value,
                style: { ...avatarStyle.value, display: isLoading.value ? 'none' : 'initial' },
                alt: __props.title || hidAlt.value || _ctx.$t('GENERAL.AVATAR'),
                title: __props.title || hidAlt.value
              }, { ...(__props.clickable ? { role: 'button' } : {}) }, {
                onError: _cache[1] || (_cache[1] = ($event: any) => (errorLoading.value = true)),
                onLoad: _cache[2] || (_cache[2] = ($event: any) => (localLoading.value = false))
              }), null, 16, _hoisted_4),
              _renderSlot(_ctx.$slots, "default"),
              (__props.editable)
                ? (_openBlock(), _createBlock(_component_vz_icon, _mergeProps({
                    key: 0,
                    clickable: "",
                    class: "vz-avatar__edit-icon",
                    name: "svg:edit",
                    size: "50%",
                    "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.EDIT' })
                  }, _toHandlers({ click: __props.externalEditor ? _ctx.$emit('edit') : () => (isEditMode.value = true) })), null, 16, ["aria-label"]))
                : (__props.verified && !isLoading.value)
                  ? (_openBlock(), _createBlock(_component_vz_icon, {
                      key: 1,
                      name: "svg:verified",
                      class: "vz-avatar__verified",
                      size: iconSize.value
                    }, null, 8, ["size"]))
                  : _createCommentVNode("", true)
            ]))
    ], 6)
  ], 2))
}
}

})
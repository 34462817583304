<template>
  <vz-infinity-scroll ref="infinityScrollRef" class="upcoming-events" :disabled="disabled" :callback="getUpcomingEventsRequest" :payload="{ userId }">
    <template #no-data>
      <div class="d-flex flex-column align-center justify-center fill-height">
        <vz-svg-href class="fill-width height-p50" name="calendar" />

        <p>{{ $t('CALENDAR.THERE_IS_NO_UPCOMING_EVENT') }}</p>
      </div>
    </template>

    <template #default="{ data }">
      <div v-for="(events, date, dateIndex) in getData(data)" :key="dateIndex" class="upcoming-events__day">
        <div v-if="events.length > 0">
          <div v-if="!isToday(+date)" class="upcoming-events__day-header text-uppercase text-title-1">
            <vz-icon name="svg:calendar" size="1.25rem" />
            <span>{{ formattedDate({ dateFrom: +date }) }}</span>
          </div>
        </div>

        <div class="upcoming-events__day-grid">
          <event-card v-for="(event, eventIndex) in events" :key="eventIndex" class="clickable pa-4" :item="event" @click="onClick(event)" />
        </div>
      </div>
    </template>
  </vz-infinity-scroll>
</template>

<script setup lang="ts">
import type { GetUpcomingEventsRes, GetUpcomingEventsReq } from 'src/views/calendar/types';
import type { CalendarEvent } from 'src/views/calendar/types';
import type { VzInfinityScrollRef } from '@shared/components/infinity-scroll/infinity-scroll.type';
import { defineAsyncComponent, type PropType, ref, watch } from 'vue';
import { GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import useCalendarStore from '@/views/calendar/composables/use-calendar-store';
import { eventsMapper } from '@/views/calendar/helpers/events-mapper';
import { formattedDate } from '../helpers';
import { isToday } from '@/views/calendar/helpers/is-today';
import { routeTo } from '@shared/composables';
import { useAssignedAuth } from '@/store/auth/helpers';

const EventCard = defineAsyncComponent(() => import(/* webpackChunkName: "calendar" */ '@/views/calendar/components/event-card.vue'));

const props = defineProps({
  disabled: { type: Boolean, default: false },
  callback: { type: Function as PropType<((payload?: GetUpcomingEventsReq) => Promise<GetUpcomingEventsRes>) | undefined>, default: undefined },
});

const { useActions: useEmployeeActions } = useCalendarStore();
const { [GET_UPCOMING_EVENTS]: getUpcomingEventsAction } = useEmployeeActions([GET_UPCOMING_EVENTS]);

const getUpcomingEventsRequest = (props.callback || getUpcomingEventsAction) as (payload?: GetUpcomingEventsReq) => Promise<GetUpcomingEventsRes>;

const { activeUser } = useAuthUser();
const { userId } = useAssignedAuth();
const infinityScrollRef = ref<VzInfinityScrollRef>(undefined);

const isUpcomingIndex = ref<number | undefined>(undefined);

const getData = (data: Array<CalendarEvent>): Record<string, Array<CalendarEvent>> => {
  const map = eventsMapper(data);
  isUpcomingIndex.value = Object.keys(map).findIndex((date) => +date > Date.now());

  return map;
};

const onClick = (item: CalendarEvent): void => {
  if (!item.route) {
    return;
  }

  routeTo({ ...item.route }, { addFromFlag: true });
};

watch(
  () => [activeUser.value?._id],
  () => {
    if (!activeUser.value?._id) {
      return;
    }

    infinityScrollRef.value?.reset();
  }
);

defineExpose({ reset: () => infinityScrollRef.value?.reset() });
</script>

<style lang="scss">
.upcoming-events {
  &__day {
    &-header {
      display: flex;
      align-items: center;
      background-color: var(--color-primary-100);
      margin-top: 1rem;
      padding: 0.25rem 0.5rem;
      gap: 0.5rem;
    }

    &-grid {
      margin: 0.5rem;
      display: flex;
      flex-direction: column;

      > *:not(:last-child) {
        border-bottom: var(--border-regular);
      }
    }
  }
}
</style>

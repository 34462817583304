import { type App } from 'vue';
import { vueValidate } from '@/shared/directives/validate';
import { vueVisibility } from '@shared/directives/visibility';
import { vueObserver } from '@shared/directives/observer';
import { vueTooltip } from '@shared/directives/tooltip';
import { vueDragResize } from '@shared/directives/drag-resize';
import { vueKeyDown } from '@shared/directives/key-down';
import { vueZIndex } from '@shared/directives/z-index';

const vzDirectives = {
  install: (app: App<Element>) => {
    app.directive('validate', vueValidate);
    app.directive('visibility', vueVisibility);
    app.directive('observer', vueObserver);
    app.directive('tooltip', vueTooltip);
    app.directive('drag-resize', vueDragResize);
    app.directive('key-down', vueKeyDown);
    app.directive('z-index', vueZIndex);
  },
};

export { vzDirectives };

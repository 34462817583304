import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineAsyncComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'notifications-tabs',
  setup(__props) {

const NotificationsGrid = defineAsyncComponent(
  () => import(/* webpackChunkName: "job-offer" */ '@/views/notifications/components/notifications-grid.vue')
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NotificationsGrid), _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-center justify-center text-center mb-6" }
const _hoisted_2 = {
  key: 0,
  class: "text-title-2 mt-8 mb-4 px-8"
}
const _hoisted_3 = { class: "text-body-1 max-line-3 px-8" }
const _hoisted_4 = { class: "d-flex align-center justify-center gap-4 flex-ch-1 px-4 mb-4" }

import type { VzPopupConfig } from '@shared/components/popup/popup.types';
import { computed, type PropType, ref } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import { CLOSE_POPUP, OPEN_POPUP } from '@shared/components/popup/popup.constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popup',
  props: {
  openEvent: { type: String as PropType<string>, default: OPEN_POPUP },
  closeEvent: { type: String as PropType<string>, default: CLOSE_POPUP },
},
  setup(__props) {



const t = useTranslator();

const modal = ref<VzPopupConfig>({} as VzPopupConfig);

const title = computed(() => {
  if (Array.isArray(modal.value.title)) {
    const [key, params] = modal.value.title;

    return t(key, params || {});
  }

  return modal.value.title;
});

const description = computed(() => {
  if (Array.isArray(modal.value.description)) {
    const [key, params] = modal.value.description;

    return t(key, params || {});
  }

  return modal.value.description;
});

const onOpenModal = (arg: VzPopupConfig) => (modal.value = (arg || {}) as any);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_button = _resolveComponent("vz-button")!
  const _component_vz_modal = _resolveComponent("vz-modal")!

  return (_openBlock(), _createBlock(_component_vz_modal, {
    "non-resizable": "",
    "hide-actions": "",
    "fit-content": "",
    "open-event": __props.openEvent,
    "close-event": __props.closeEvent,
    onOpen: onOpenModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (title.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t(title.value)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(description.value)), 1),
        (modal.value.icon)
          ? (_openBlock(), _createBlock(_component_vz_icon, {
              key: 1,
              class: "mt-4",
              size: modal.value.icon.size || '3.5rem',
              name: modal.value.icon.name,
              color: modal.value.icon.color
            }, null, 8, ["size", "name", "color"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(modal.value.actions || [], ({ label, request, color }, index) => {
          return (_openBlock(), _createBlock(_component_vz_button, {
            key: index,
            color: color,
            text: label,
            disabled: request?.loading,
            loading: request?.loading,
            onClick: ($event: any) => (request?.call())
          }, null, 8, ["color", "text", "disabled", "loading", "onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["open-event", "close-event"]))
}
}

})
const WAVEFORM_BASE_COLOR = 'rgba(0,0,0,0.1)';
const WAVEFORM_PROGRESS_COLOR = 'rgba(0,75,200,0.8)';
const WAVEFORM_COMPLETE_COLOR = 'rgba(0,0,0,0.5)';

const CONTAINER_HEIGHT = 60;
const CANVAS_HEIGHT = CONTAINER_HEIGHT - 24;
const PLAY_BUTTON_SIZE = CONTAINER_HEIGHT - 16;

const CSS_STYLE = `
.audio-player-container {
  direction: ltr;
  user-select: none;
  padding: 0 8px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  height: ${CONTAINER_HEIGHT}px;
  transition: opacity 0.3s ease-in-out;
}

.audio-player--loading > * {
  opacity: 0.3;
}

.audio-player--loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.8) 20%, transparent 80%) no-repeat 0 0 / 35% 100%;
  animation: skeleton 2s linear infinite;
}

@keyframes skeleton {
  0% { background-position: -100% 0; }
  100% { background-position: 200% 0; }
}

.audio-player-playback-speed {
  background-color: rgba(0,0,0,0.1);
  padding: 2px 4px;
  text-align: center;
  border-radius: 8px;
  width: 64px;
  text-wrap: nowrap;
}

canvas {
  display: block;
  flex-grow: 1;
  height: ${CANVAS_HEIGHT + 2}px;
  width: calc(100% - ${PLAY_BUTTON_SIZE + 32 + 64}px);
  position: relative;
  z-index: 1;
  opacity: 0.8;
}

.audio-player--playing > canvas {
  width: calc(100% - ${PLAY_BUTTON_SIZE + 32 + 64}px);
}

.audio-player-button {
  padding: 0;
  padding: 4px;
  width: ${PLAY_BUTTON_SIZE}px;
  height: ${PLAY_BUTTON_SIZE}px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-player-runtime {
  position: absolute;
  right:0;
  margin-inline-start: 10px;
  bottom: -8px;
}
`;

// eslint-disable-next-line max-len
const PLAY_SVG = `<path fill="currentColor" d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>`;
// eslint-disable-next-line max-len
const STOP_SVG = `<path fill="currentColor" d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>`;

class AudioPlayer extends HTMLElement {
  private frameId: number | null;
  private audioSamples: Array<number> | null = null;
  private isSeeking: boolean = false;
  private sourceNode: MediaElementAudioSourceNode | null;
  private ctx: CanvasRenderingContext2D;
  private audioContext: AudioContext;
  private readonly analyser: AnalyserNode;
  private readonly audio: HTMLAudioElement;

  private readonly container: HTMLDivElement;
  private readonly playbackSpeed: HTMLDivElement;
  private readonly waveformCanvas: HTMLCanvasElement;
  private readonly svgImage: SVGSVGElement;
  private readonly playButton: HTMLButtonElement;
  private readonly playTime: HTMLDivElement;

  constructor() {
    super();
    // Flex container
    this.container = document.createElement('div');
    this.container.classList.add('audio-player-container');

    // Play button
    this.playButton = document.createElement('button');
    this.playButton.classList.add('audio-player-button');
    this.playButton.onclick = (event: MouseEvent) => this.togglePlay(event);
    this.container.appendChild(this.playButton);

    // Canvas for waveform visualization
    this.waveformCanvas = document.createElement('canvas');
    this.container.appendChild(this.waveformCanvas);
    this.ctx = this.waveformCanvas.getContext('2d')!;
    this.initCanvas();

    this.playbackSpeed = document.createElement('div');
    this.playbackSpeed.classList.add('audio-player-playback-speed');
    this.playbackSpeed.innerText = '1x';
    this.playbackSpeed.onclick = () => this.speed();
    this.container.appendChild(this.playbackSpeed);

    // SVG button
    const svgNamespace = 'http://www.w3.org/2000/svg'; // SVG namespace
    this.svgImage = document.createElementNS(svgNamespace, 'svg');
    this.svgImage.setAttribute('viewBox', '0 0 16 16');
    this.svgImage.setAttribute('height', '100%');
    this.svgImage.setAttribute('width', '100%');
    this.svgImage.innerHTML = PLAY_SVG;
    this.playButton.appendChild(this.svgImage);

    // Player runtime display
    this.playTime = document.createElement('div');
    this.playTime.classList.add('audio-player-runtime');
    this.container.appendChild(this.playTime);

    // Audio element (not appended to the DOM, used for playback)
    this.audio = document.createElement('audio');
    this.audio.onerror = () => this.changeAudioPlayerState('ERROR', true);

    // Web Audio API
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    this.frameId = null;

    // Initialize sourceNode to null
    this.sourceNode = null;
  }

  connectedCallback(): void {
    this.loadAudioSource();

    // Create and connect the source node once
    if (!this.sourceNode) {
      this.sourceNode = this.audioContext.createMediaElementSource(this.audio);
      this.sourceNode.connect(this.analyser);
      this.analyser.connect(this.audioContext.destination);
    }

    // CSS
    const style = document.createElement('style');
    style.textContent = CSS_STYLE;
    this.appendChild(style);
    this.appendChild(this.container);

    this.initEventListeners();
  }

  static get observedAttributes(): string[] {
    return ['src', 'loading'];
  }

  attributeChangedCallback(name: string): void {
    switch (name) {
      case 'src':
        this.loadAudioSource()
          .then(() => this.changeAudioPlayerState('ERROR', false))
          .catch(() => this.changeAudioPlayerState('ERROR', true));
        break;
      case 'loading':
        this.changeAudioPlayerState('LOADING', this.getAttribute('loading') === 'true');
        break;
    }
  }

  async play() {
    try {
      await this.audio.play();
    } catch (error) {
      console.error('AudioPlayer:play', error);
    }
  }

  pause() {
    this.audio.pause();
  }

  seek(time: number) {
    this.audio.currentTime = isFinite(this.audio.duration) ? Math.min(Math.max(time, 0), this.audio.duration) : Math.min(time, 0);
  }

  speed(value: number = parseFloat(this.playbackSpeed.innerText || '1')) {
    const playbackSpeed = value < 2 ? value + 0.5 : 1;
    this.audio.playbackRate = playbackSpeed;
    this.playbackSpeed.innerText = `${playbackSpeed}x`;
  }

  private initEventListeners(): void {
    // Adding loadedmetadata event listener
    this.audio.addEventListener('loadedmetadata', () => {
      this.audio.addEventListener('play', () => this.onPlay());
      this.audio.addEventListener('pause', () => this.onStop());
      this.audio.addEventListener('ended', () => this.onEnd());
    });

    this.audio.addEventListener('canplay', () => {
      this.changeAudioPlayerState('EMPTY-STATE', false);
      this.changeAudioPlayerState('ERROR', false);
      this.onTimeUpdate();
    });

    this.audio.addEventListener('timeupdate', () => this.onTimeUpdate());

    // Add event listeners for seeking
    this.waveformCanvas.addEventListener('mousedown', this.startSeeking.bind(this));
    this.waveformCanvas.addEventListener('mousemove', this.moveSeeking.bind(this));
    this.waveformCanvas.addEventListener('mouseup', this.stopSeeking.bind(this));
    this.waveformCanvas.addEventListener('mouseleave', this.stopSeeking.bind(this));

    // For touch devices
    this.waveformCanvas.addEventListener('touchstart', this.startSeeking.bind(this));
    this.waveformCanvas.addEventListener('touchmove', this.moveSeeking.bind(this));
    this.waveformCanvas.addEventListener('touchend', this.stopSeeking.bind(this));
  }

  private startSeeking(event: MouseEvent | TouchEvent): void {
    event.stopPropagation();
    this.isSeeking = true;
    this.moveSeeking(event);
  }

  private moveSeeking(event: MouseEvent | TouchEvent): void {
    if (!this.isSeeking || !isFinite(this.audio.duration)) {
      return;
    }

    event.stopPropagation();
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const rect = this.waveformCanvas.getBoundingClientRect();
    const x = clientX - rect.left;
    const newTime = (x / this.waveformCanvas.width) * this.audio.duration;
    this.audio.currentTime = Math.min(Math.max(newTime, 0), this.audio.duration);
  }

  private stopSeeking(): void {
    this.drawWaveform(WAVEFORM_BASE_COLOR, 0);
    this.isSeeking = false;
  }

  private changeAudioPlayerState(key: 'EMPTY-STATE' | 'ERROR' | 'LOADING', value: boolean): void {
    if (value) {
      this.container.classList.add(`audio-player--${key.toLowerCase()}`);
    } else {
      this.container.classList.remove(`audio-player--${key.toLowerCase()}`);
    }
  }

  private async loadAudioSource(): Promise<void> {
    const src = this.getAttribute('src');
    this.audioSamples = null;
    this.playTime.innerHTML = '';

    if (src) {
      this.audio.src = src;
      this.decodeAndDrawWaveform();
    } else {
      this.changeAudioPlayerState('EMPTY-STATE', true);
    }
  }

  private async togglePlay(event: MouseEvent): Promise<void> {
    event.stopPropagation();

    // Resume the audio context if it's suspended
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }

    if (this.audio.paused) {
      await this.audio.play();
    } else {
      this.audio.pause();
    }

    this.onStop();
  }

  private initCanvas(): void {
    this.waveformCanvas.height = CANVAS_HEIGHT;
    this.drawWaveform(WAVEFORM_BASE_COLOR, 1);
  }

  private animateCanvas() {
    this.frameId = requestAnimationFrame(() => this.animateCanvas());

    // Update playback progress on waveform
    this.drawWaveform(WAVEFORM_PROGRESS_COLOR, this.audio.currentTime / this.audio.duration); // Color for played part
  }

  private drawWaveform(color: string, percents: number = 0) {
    const { width, height } = this.waveformCanvas;

    if (percents === 1) {
      this.ctx.clearRect(0, 0, width, height);
    } else if (!percents) {
      this.drawWaveform(WAVEFORM_BASE_COLOR, 1);
    }

    this.ctx.strokeStyle = color;
    this.ctx.beginPath();

    if (!this.audioSamples) {
      this.ctx.moveTo(0, height / 2);
      this.ctx.lineTo(width * percents, height / 2);
    } else {
      const audioSamples = this.audioSamples.slice(0, this.audioSamples.length * percents);

      for (let i = 0; i < audioSamples.length * percents; i++) {
        const x = i * ((width * percents) / audioSamples.length);
        const y = (1 - (audioSamples[i] * 4 + 1) / 2) * height;

        if (i === 0) {
          this.ctx.moveTo(x, y);
        } else {
          this.ctx.lineTo(x, y);
        }
      }
    }

    this.ctx.stroke();
  }

  private decodeAndDrawWaveform(color: string = WAVEFORM_BASE_COLOR): void {
    this.drawWaveform(color);

    if (this.audioSamples) {
      return;
    } else if (!this.audio?.src) {
      console.error('AudioPlayer:decodeAndDrawWaveform', 'No audio source');

      return;
    }

    fetch(this.audio.src)
      .then((response) => {
        response
          .arrayBuffer()
          .then((arrayBuffer) => {
            this.audioContext
              .decodeAudioData(arrayBuffer)
              .then((audioBuffer) => {
                const rowData = audioBuffer.getChannelData(0);
                this.audioSamples = this.downSampleData(rowData, 800);
                this.drawWaveform(color);
              })
              .catch((error) => console.error('AudioPlayer:decodeAndDrawWaveform', 'decodeAudioData', error));
          })
          .catch((error) => console.error('AudioPlayer:decodeAndDrawWaveform', 'arrayBuffer', error));
      })
      .catch((error) => console.error('AudioPlayer:decodeAndDrawWaveform', 'fetch', error));
  }

  private onPlay() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
    }

    this.container.classList.add('audio-player--playing');
    this.animateCanvas();
  }

  private onEnd(): void {
    this.onStop();

    this.drawWaveform(WAVEFORM_COMPLETE_COLOR, 1);

    const endEvent = new CustomEvent('end', { bubbles: true, composed: true });
    this.dispatchEvent(endEvent);
  }

  private onStop(): void {
    if (this.audio.paused) {
      this.svgImage.innerHTML = PLAY_SVG;
      cancelAnimationFrame(this.frameId!);
      this.container.classList.remove('audio-player--playing');
    } else {
      this.svgImage.innerHTML = STOP_SVG;
    }
  }

  private onTimeUpdate(): void {
    if (this.playTime.innerHTML && this.audio.paused) {
      return;
    }

    const formatTime = (time: [number, number]) => {
      return time
        .filter((value) => isFinite(value))
        .map((value) => {
          const minutes = Math.floor(value / 60);
          const seconds = Math.floor(value % 60);

          return `<span>${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}</span>`;
        })
        .join(' / ');
    };

    this.playTime.innerHTML = formatTime([this.audio.currentTime, this.audio.duration]);
  }

  private downSampleData(data: Float32Array, samples: number) {
    const step = Math.ceil(data.length / samples);
    const downSampled = [];

    for (let i = 0; i < data.length; i += step) {
      downSampled.push(data[i]);
    }

    return downSampled;
  }
}

customElements.define('audio-player', AudioPlayer);
